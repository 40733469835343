<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 mb-2 py-3">
    <div class="col-xs-12 col-md-12 mb-5">
      <h2 class="mb-4 c-form-title">
        {{ 'userRegistration.accountInformation.header' | cxTranslate }}
      </h2>
      <h4 class="mb-4 c-form-copy">
        {{ 'userRegistration.accountInformation.title' | cxTranslate }}
      </h4>
      <p class="text-fixed-legal-copy">
        {{ 'userRegistration.accountInformation.formTitle' | cxTranslate }}
      </p>
      <div class="col-md-12 c-alert-banner c-alert-banner--fail" [ngClass]="{ 'acc-invalid': billToShipError === true }"
        *ngIf="errorMsg">
        <span class="alert-icon">
          <cx-icon class="cx-icon fas fa-exclamation-circle" ng-reflect-type="ERROR">
          </cx-icon>
        </span>
        <span class="dismissible-error-message"> {{ bannerMessage | cxTranslate }} </span>
      </div>
    </div>
    <form [formGroup]="accountInfoForm" id="accountInfoForm" (ngSubmit)="validateAccountInfo()">
      <div class="col-xs-12 col-sm-6 col-md-6 mb-2">
        <div id="registerBilling" class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mb-3 c-text-input">
            <ng-template #accountNumberPopContent>
              {{ 'userRegistration.accountInformation.accountNumber.popoverMsg' | cxTranslate }}
            </ng-template>
            <label for="accountNumber" class="Fields-label icon-label d-inline c-form-label">
              {{ 'userRegistration.accountInformation.accountNumber.label' | cxTranslate }}
            </label>
            <a placement="top" class="d-inline" [ngbPopover]="accountNumberPopContent">
              <!-- <img
              src="../../assets/icons/faqs_reg.png" alt="faq_reg" class="faq" /> -->
              <i color="red" class="fa fa-question-circle c-icon-primary-sm"></i>
            </a>
            <input id="accountNumber" name="accountNumber" data-valid="valid_facility"
              aria-labelledby="accountNumber tooltipFacilityName" type="text" placeholder="XXXXXXXXX"
              class="Fields-input" autocomplete="off" formControlName="accountNumber" data-di-id="#accountNumber" />

            <cx-form-errors *ngIf="
              !accountInfoForm.get('accountNumber').errors?.required
              && !accountInfoForm.get('accountNumber').valid
              && accountInfoForm.get('accountNumber').touched" class="errorMessage">
              <p>
                {{ 'userRegistration.accountInformation.accountNumber.validationMessage.acceptsNumberValidation'
                | cxTranslate }}
              </p>
            </cx-form-errors>

            <cx-form-errors *ngIf="
                accountInfoForm.get('accountNumber').errors?.required
                && !accountInfoForm.get('accountNumber').valid
                && accountInfoForm.get('accountNumber').touched" class="defaultMessage" role="alert"
              aria-live="assertive">
              <p>
                {{ 'userRegistration.accountInformation.requiredMsg' | cxTranslate }}
              </p>
            </cx-form-errors>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 mb-3 c-text-input">
            <label for="zipCode" class="Fields-label c-form-label">
              {{ 'userRegistration.accountInformation.postalCode.label' | cxTranslate }}
            </label>
            <input id="postalCode" name="postalCode" data-valid="valid_zip_canada" type="text" maxlength="7"
              class="Fields-input" autocomplete="off" formControlName="postalCode" data-di-id="#postalCode" />

            <cx-form-errors *ngIf="
              accountInfoForm.get('postalCode').errors?.pattern
              && !accountInfoForm.get('postalCode').valid
              && accountInfoForm.get('postalCode').touched" class="errorMessage" role="alert" aria-live="assertive">
              <p>
                {{ 'userRegistration.accountInformation.postalCode.invalidFormatMsg' | cxTranslate }}
              </p>
            </cx-form-errors>

            <cx-form-errors *ngIf="
              accountInfoForm.get('postalCode').errors?.required
              && !accountInfoForm.get('postalCode').valid
              && accountInfoForm.get('postalCode').touched" class="defaultMessage" role="alert" aria-live="assertive">
              <p>
                {{ 'userRegistration.accountInformation.requiredMsg' | cxTranslate }}
              </p>
            </cx-form-errors>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 mb-2">
        <!-- email -->
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 mb-3 c-text-input mb-3">
            <label for="userEmail" class="c-form-label"> {{ "userRegistration.userInformation.email.label" | cxTranslate
              }}
            </label>
            <input id="userEmail" name="email" data-valid="valid_mail" type="text" class="Fields-input"
              data-view="newemail" formControlName="email" data-di-id="#userEmail" />
            <cx-form-errors *ngIf="
              !!accountInfoForm.get('email').value
              && accountInfoForm.get('email').invalid
              && accountInfoForm.get('email').touched" class="defaultMessage" role="alert" aria-live="assertive">
              <p>
                {{ "userRegistration.userInformation.email.invalidEmail" | cxTranslate }}
              </p>
            </cx-form-errors>

            <cx-form-errors *ngIf="
              accountInfoForm.get('email').errors?.required
              && !accountInfoForm.get('email').valid
              && accountInfoForm.get('email').touched" class="defaultMessage" role="alert" aria-live="assertive">
              <p>
                {{ "userRegistration.userInformation.requiredMsg" | cxTranslate }}
              </p>
            </cx-form-errors>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mt-3" *ngIf="infoMsg && isIndirectB2BUnitValidated">
            <span class="dismissable-info-message">
              {{ "userRegistration.accountInformation.emailExistOnAkamaiMsg" | cxTranslate }}
            </span>
          </div>
          <div *ngIf="existingUserContinue" class="col-xs-12 col-sm-6 col-md-6 mb-2"></div>
          <div *ngIf="existingUserContinue" class="col-xs-12 col-sm-6 col-md-6 mb-3 c-text-input mb-3">
            <label for="userConfirmEmail" class="c-form-label">
              {{ "accountRegistration.form.confirm_email.label" | cxTranslate }}
            </label>
            <input id="userConfirmEmail" name="confirmEmail" data-valid="valid_mail" type="text" class="Fields-input"
              data-view="confirmEmail" formControlName="confirmEmail" data-di-id="#userConfirmEmail" />
            <cx-form-errors *ngIf="
              !!accountInfoForm.get('confirmEmail').value
              && accountInfoForm.get('confirmEmail').invalid
              && accountInfoForm.get('confirmEmail').touched" class="defaultMessage" role="alert"
              aria-live="assertive">
              <p>
                {{ "userRegistration.userInformation.email.invalidEmail" | cxTranslate }}
              </p>
            </cx-form-errors>

            <cx-form-errors *ngIf="
              accountInfoForm.get('confirmEmail').errors?.required
              && !accountInfoForm.get('confirmEmail').valid
              && accountInfoForm.get('confirmEmail').touched" class="defaultMessage" role="alert"
              aria-live="assertive">
              <p>
                {{ "userRegistration.userInformation.requiredMsg" | cxTranslate }}
              </p>
            </cx-form-errors>

            <cx-form-errors *ngIf="
              !!accountInfoForm.get('confirmEmail').value
              && !accountInfoForm.get('confirmEmail').invalid
              && accountInfoForm?.errors?.emailNotMatch" class="errorMessage">
              <p>
                {{ "userRegistration.userInformation.confirmEmail.emailNotMatch" | cxTranslate }}
              </p>
            </cx-form-errors>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 mb-2">
            <div class="row">
              <div *ngIf="infoMsg && isIndirectB2BUnitValidated"
                class="col-xs-12 col-sm-12 col-md-12 mb-3 c-text-input">
                <label for="userPassword" class="c-form-label">
                  {{ "userRegistration.userInformation.password.label" | cxTranslate }}
                </label>
                <input id="userPassword" name="password" data-valid="valid_facility"
                  aria-labelledby="password tooltipFacilityName" type="password" class="Fields-input" autocomplete="off"
                  formControlName="password" data-di-id="#userPassword" />
                <a (click)="sendResetPasswordEmail()" class="c-link" href=" javascript: void(0);">
                  {{ "userRegistration.accountInformation.emailExistOnAkamaiMsgLink" | cxTranslate }} </a>
                <cx-form-errors *ngIf="
                  accountInfoForm.get('password').errors?.required
                  && !accountInfoForm.get('password').valid
                  && accountInfoForm.get('password').touched" class="defaultMessage" role="alert"
                  aria-live="assertive">
                  <p>
                    {{ "userRegistration.userInformation.requiredMsg" | cxTranslate }}
                  </p>
                </cx-form-errors>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-4 c-text-input">
          <div class="col-xs-12 col-sm-12 col-md-6 captcha-x"
            *ngIf="accountInfoForm.get('recaptchaReactive') && isCaptchaEnabled$ | async">
            <re-captcha formControlName="recaptchaReactive" (resolved)="resolved($event)"></re-captcha>
            <cx-form-errors
              *ngIf="
                accountInfoForm.get('recaptchaReactive').errors?.required &&
                !accountInfoForm.get('recaptchaReactive').valid &&
                accountInfoForm.get('recaptchaReactive').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >
              <p>
                {{ 'userRegistration.userInformation.requiredMsg' | cxTranslate }}
              </p>
            </cx-form-errors>
          </div>
        </div>
      </div>
      <hr>
      <div class="col-sm-8 col-md-8 mb-4">
        <button type="submit" class="c-button c-button--primary c-button--lg reviewvalidbtn"
          data-di-id="di-id-7a6cb2cd-cb37948a">
          {{ (isIndirectB2BUnitValidated && infoMsg ? "userRegistration.accountInformation.buttonContinue" :
          "userRegistration.accountInformation.buttonLabel") | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>