<app-loader *ngIf="!showdiagram && salesOrg != 'CA04'"></app-loader>
<div class="row d-flex mb-4 pl4" *ngIf="showdiagram">
  <div class="col-md-6 col-sm-12 mb-4 mb-md-0">
    <div class="card-box h-100">
      <h3 class="c-heading"> {{ 'accountOverview.amountSpent' | cxTranslate }} </h3>
      <div class="row">
        <h5 class="font-bold my-4"> {{ 'accountOverview.allProducts' | cxTranslate }} </h5>
        <div id="rect-progress-bar">
          <span> {{ remaining_amount <= 0 ?  ('accountOverview.amountAchieved' | cxTranslate) : (actual_amount_value | currency) }} </span>
          <progress value="{{ actual_amount_value }}" max="{{ total_amount }}"></progress>
        </div>
        <div class="d-flex justify-content-between w-100">
          <div *ngIf="days_left > 0">
            <p class="code_red">{{ days_left }} {{ 'accountOverview.daysRemainingToQualify' | cxTranslate }}</p>
          </div>        
            <p class="st_calcval">
              <span *ngIf="actual_amount_value">{{ actual_amount_value | currency }} / {{ total_amount | currency }}</span>
            </p>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <circle-progress class="circle__progress1" [percent]="actual_amount_percentage"
          [outerStrokeColor]="'#7b00e6'" [innerStrokeColor]="innerStrokeColor2"
          [subtitle]="actual_amount_percentage+'%'" [subtitleColor]="'#7b00e6'"
          outerStrokeLinecap="'line'"></circle-progress>
      </div>
      <h2 class="text-center font-bold">
        {{ remaining_amount | currency}} {{ 'accountOverview.toGo' | cxTranslate }}
      </h2>
      <h2 class="text-center font-bold" *ngIf="remaining_amount <= 0">
          {{ 'accountOverview.amountAchieved' | cxTranslate }}
      </h2>
    </div>
  </div>

  <div class="col-md-6 col-sm-12 mb-4 mb-md-0">
      <div class="card-box h-100">
        <h3 class="c-heading"> {{ 'accountOverview.dosesPurchased' | cxTranslate }} </h3>
        <div class="row pt-40">
          <h5 class="font-bold my-4"> {{ 'accountOverview.allProducts' | cxTranslate }} </h5>
          <div id="rect-progress-bar">
            <span> {{ actual_doses }} </span>
            <progress class="m0" value="{{ actual_doses }}"></progress>
          </div>
        </div>
        <div class="st_calcval"></div>
        <div class="d-flex justify-content-center">
          <circle-progress class="circle__progress"
            [subtitle]="'accountOverview.dosesProgress' | cxTranslate"
            [subtitleColor]="'#7b00e6'"
            [innerStrokeColor]="innerStrokeColor1"
            [subtitleFontSize]="22"
            outerStrokeLinecap="'line'">
          </circle-progress>
        </div>
          <h2 class="text-center font-bold">
            {{ actual_doses }} {{ 'accountOverview.dosesPurchased' | cxTranslate }}
          </h2>
      </div>
    </div>
    
    <div class="col-md-12 msg-box">
      <div class="card-box h-100">
       <b class="txt">{{ 'accountOverview.dashboardMsgCriteria' | cxTranslate }}</b> 
        {{ 'accountOverview.dashboardMsgCriteriaMsg' | cxTranslate }}
       <b class="txt">{{ 'accountOverview.dashboardMsgCriteriaMsgBd' | cxTranslate }}</b> 
        {{ 'accountOverview.dashboardMsgCriteriaAnd' | cxTranslate }}
      </div>
  </div>
</div>
