import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { LoactionStripService } from 'src/app/shared/modules/location-strip/loaction-strip.service';
import * as _ from 'underscore';
import { CustomPdpService } from '../../../custom-pdp/custom-pdp.service';
import { ProductUtils } from '../../../shared/utils/productUtils';
import { CustomAddToCartService } from '../../custom-add-to-cart.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  @Input() public productDetails;
  @Input() public standardCartCode;
  @Input() public unitSection;
  description: any;
  monograph: any;
  monographLink: any;
  itemAddedQuantity: any;
  baseUrl: string;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  userDetails: any;
  cartId: any;
  cartItems: any;
  volumePrices: any;
  prodCodeSub: Subscription;
  pdpCartItemsSub: Subscription;
  allCartItemSub: Subscription;
  cartDetailsSub: Subscription;
  createdCartSub: Subscription;
  updatedCartDetailsSub: Subscription;
  images: any;
  isCartPage: boolean = false;
  salesOrg$ = this.locStrpService.salesOrg$;
  constructor(
    private readonly addToCart: CustomAddToCartService,
    private readonly modalService: NgbModal,
    private readonly pdpService: CustomPdpService,
    private readonly user: UserAccountFacade,
    private readonly sanitizer: DomSanitizer,
    private readonly occEndPoint: OccEndpointsService,
    private locStrpService: LoactionStripService,
    private readonly router: RoutingService,
    private readonly dataLayerService: DataLayerService
  ) {
    router.getPageContext().subscribe((page) => {
      if (page?.id == '/cart' || page?.id == '/cart/manage-saved-cart') {
        this.isCartPage = true;
      } else {
        this.isCartPage = false;
      }
    });
  }

  ngOnInit(): void {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.pdpService.productCode.next(false);
    this.pdpService.getPDPCategory(this.productDetails?.product?.code);
    let imagesFormat = _.clone(
      _.findWhere(this.productDetails?.product?.images, { format: 'product' })
    );
    if (imagesFormat) imagesFormat.url = this.baseUrl + imagesFormat?.url;
    this.images = { PRIMARY: imagesFormat };
    this.prodCodeSub = this.pdpService.productCode$.subscribe((res) => {
      if (res) {
        this.volumePrices = res?.volumePrices;
      } else {
        this.volumePrices = [];
      }
    });
    this.description = this.productDetails?.product?.description;
    this.monograph = this.productDetails?.product?.productDocuments;
    if (this.monograph) {
      for (const link of this.monograph?.productDocument) {
        this.monographLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          link.url
        );
      }
    }
    this.user.get().subscribe((res) => {
      if (res) {
        this.userDetails = res;
        if (this.userDetails) {
          this.pdpService.getCarts(this.userDetails?.userName);
        }
      }
    });
    this.pdpCartItemsSub = this.pdpService.cartItems$.subscribe((res) => {
      if (res) {
        this.cartDataMapping(res);
      }
    });
    this.allCartItemSub = this.addToCart.allCartItems$.subscribe((res) => {
      if (res) {
        this.cartDataMapping(res);
      }
    });
    this.dataLayerService.viewItemDataLayer(
      this.productDetails,
      'standard process',
      'pdp-popup'
    );
  }
  cartDataMapping(response) {
    this.cartItems = response?.carts;
    this.cartItems = this.cartItems.filter(
      (item) => item?.saveTime === undefined
    );
    this.cartItems = this.cartItems[this.cartItems?.length - 1];
    this.cartId = this.cartItems?.code;
  }
  performQuantityComputation(operator, idData, quantityOfUnits): void {
    ProductUtils.alterQuantity(operator, idData, quantityOfUnits);
  }
  updateCart(idData, popover, prod): void {
    const inputElement = document.getElementById(
      `prodQuantity${idData}`
    ) as HTMLInputElement;
    const params = [
      {
        entryNumber: this.productDetails?.entryNumber,
        quantity: inputElement.value,
      },
    ];

    this.addToCart.updateCartDetails(
      this.standardCartCode,
      params,
      this.cartId
    );

    this.cartDetailsSub = this.addToCart.cartDetails$.subscribe((res: any) => {
      if (res) {
        this.successPopoverDisplay(popover);
        this.dataLayerService.addCartDataLayer(
          prod,
          'standard process',
          inputElement.value,
          'cart item details'
        );
      }
    });

    this.cartDetailsSub = this.addToCart.cartDetails$.subscribe((res: any) => {
      if (res) {
        this.successPopoverDisplay(popover);
      }
    });
  }
  close(): void {
    this.modalService.dismissAll();
  }
  addToSessionCart(idData, productCode, popover): void {
    const inputElement = document.getElementById(
      `prodQuantity${idData}`
    ) as HTMLInputElement;
    const params = {
      product: {
        code: productCode,
      },
      quantity: inputElement.value,
    };
    this.itemAddedQuantity = inputElement.value;

    if (this.cartItems && this.cartItems?.length === 0) {
      this.pdpService.createCart(this.userDetails?.userName);
      this.createdCartSub = this.pdpService.createdCart$.subscribe((res) => {
        if (res) {
          this.cartId = res?.code;
          this.pdpService.getCarts(this.userDetails?.userName);
          this.updateCartDetails(params, popover);
        }
      });
    } else {
      if (this.cartId === undefined) {
        this.cartItems =
          this.cartItems && this.cartItems?.length > 0
            ? this.cartItems[this.cartItems?.length - 1]
            : [];
        this.cartId = this.cartItems?.code;
      }
      this.updateCartDetails(params, popover);
    }
  }
  updateCartDetails(params, popover) {
    this.pdpService.updateCart(params, this.userDetails?.userName, this.cartId, params.quantity);
    this.updatedCartDetailsSub = this.pdpService.cartDetailsUpdate$.subscribe(
      (res) => {
        if (res) {
          this.successPopoverDisplay(popover);
        }
      }
    );
  }
  successPopoverDisplay(popover) {
    const num = 2500;
    popover.toggle();
    setTimeout(() => popover.close(), num);
  }
  ngOnDestroy() {
    this.prodCodeSub?.unsubscribe();
    this.prodCodeSub?.unsubscribe();
    this.pdpCartItemsSub?.unsubscribe();
    this.allCartItemSub?.unsubscribe();
    this.cartDetailsSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.updatedCartDetailsSub?.unsubscribe();
  }
}
