import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoactionStripService } from 'src/app/shared/modules/location-strip/loaction-strip.service';
import { SharedService } from '../../../shared/services/shared.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-salestrack',
  templateUrl: './salestrack.component.html',
  styleUrls: ['./salestrack.component.scss'],
})
export class SalestrackComponent implements OnInit {
  actual_doses: any;
  actual_amount_value: number;
  total_amount: number;
  days_left: number;
  remaining_amount: number;
  actual_amount_percentage: number;
  innerStrokeColor1: string;
  innerStrokeColor2: string;
  showdiagram: boolean;
  salesOrg = this.locService.salesOrg$.value;

  constructor(
    private readonly sharedService: SharedService,
    private readonly cdr: ChangeDetectorRef,
    private readonly locService: LoactionStripService
  ) {}

  ngOnInit(): void {
    this.showdiagram = false;
      this.getsalestrackingdet();
      this.sharedService.getSalesTrackAPI(localStorage.getItem('user_login_id'), localStorage.getItem('user_account_id'));
  }

  async getsalestrackingdet() {
    this.locService.getUserAccountDataFromDb(localStorage.getItem('user_login_id'));
    await this.sharedService.salesTrackDet$.subscribe((result) => {
      if (!_.isEmpty(result)) {
        if (this.salesOrg != "CA04") {
          this.actual_doses = result?.actualDoses;
          this.actual_amount_value = result?.actualAmount;
          this.days_left = result?.daysLeft;
          this.total_amount = result?.totalAmount;
          this.remaining_amount = result?.remainingAmount;
          this.actual_amount_percentage = Math.floor(result?.totalAmountPercentage);
          this.innerStrokeColor1 = this.actual_doses <= 0 ? '#bbbec2' : '#7b00e6';
          if (this.remaining_amount <= 0) {
            this.actual_amount_percentage = 100;
            this.innerStrokeColor2 = '';
          } else {
            this.innerStrokeColor2 = '#bbbec2';
          }
        }
        this.cdr.detectChanges();
        this.showdiagram = true;
      } else {
        this.actual_doses = '';
        this.actual_amount_percentage = 0;
        this.showdiagram = true;
        this.innerStrokeColor1 = '#bbbec2';
        this.innerStrokeColor2 = '#bbbec2';
      }
    });
  }
}